@mixin aside-square-helper() {
  content: '';
  width: 0;
  max-width: 100%;
  vertical-align: middle;
  padding-top: 100%;
  white-space: normal;
}

@mixin relative-block-full() {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin reset-lists($type){
  $resets: (
          ul: (
                  list-style: none,
                  margin: 0,
                  padding: 0,
                  li: (
                          margin: 0,
                          padding: 0
                  )
          )
  );
  @each $prop, $value in map-get($resets, $type) {
    @if type-of($value) == 'map' {
      & > #{$prop} {
        @each $sub-prop, $sub-value in $value {
          #{$sub-prop}: $sub-value;
        }
      }
    } @else {
      #{$prop}: $value;
    }
  }
}