h1 {
  color: $sw-h1-col;
  margin-bottom: 1rem;
}

.h2,
h2 {
  color: $sw-h2-col;
  padding-bottom: $spacer;
  border-bottom: 2px solid #f5f5f5;
  margin-bottom: $spacer*2;
}

.light {
  .h2,
  h2 {
    border-bottom: 2px solid $white;
  }
}

h3 {
  color: $sw-h3-col;
}

h5 {
  color: $sw-h5-col;
}

hr {
  border-top: 2px solid $border-color;
}

.light {
  hr {
    border-top: 2px solid $white;
  }
}

aside a,
a.box {

  p   { color: $body-color;   }
  h2  { color: $sw-h2-col;    }
  i   { color: $primary       }

}

aside {
  a {
    .h2 {
      border: none;
    }
  }
}

.box ul,
section ul,
.section ul {
  list-style: none;
  margin: 0 0 2rem 0;
  padding-left: 1.5rem;

  li {
    position: relative;


    //text-indent: -1rem;
    &:before {
      content: "";
      line-height: 2em;
      width: .5rem;
      height: .5rem;
      background-color: $primary;
      position: absolute;
      margin: .5rem .5rem 0 -1rem;
      vertical-align: middle;
      display: inline-block;
    }

  }
}

.padded-multiline {
  //line-height: 1.4;
  padding: 2px 0;
  margin: 0 auto;

}
.padded-multiline span {
  display: inline;
  padding: 0 1rem;
  background: rgba(0,0,0,.5);

  /* Needs prefixing */
  box-decoration-break: clone;
  color: #fff;
}

div.padded-multiline {
  margin: 0;
  padding: 0;
  span {
    margin: 0;
    line-height: 1.75;
    font-size: 1.5rem;
  }
}

.h3.padded-multiline {
  margin-bottom: 5px;
  span {
    font-size: 2.5rem;
    line-height: 1.65;

  }
}