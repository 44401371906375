.google-map {
  position: relative;
  padding-bottom: 50%; // This is the aspect ratio
  height: 0;
  overflow: hidden;
}
.google-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}