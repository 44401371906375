.img-fluid {
  width: 100%;
}



@include media-breakpoint-down(md) {
  .event {
    .col-lg-3 {
      .img-fluid {
        width: 240px;
        display: block;
        margin: $spacer auto;
      }
    }
  }
}