
.btn {
  display: inline-flex;
  vertical-align: middle;
}

.btn-lg {
  margin: $spacer 0;
}

.btn-light {
  color: #212529;
  background-color: $white;
  border-color: $border-color;
}

.btn-block {
  position:relative;
  margin: 0;
  display: flex;
  align-items: center;
  text-align: left;

  i {
    display: inline-block;
    position: absolute;
    right: 1rem;
    color: $primary;
  }

  &.btn-light {
    font-size: 1rem;
    padding: 1rem 50px 1rem 1rem;

    .material-icons {
      line-height: 1;
    }
  }
}

.finder {
  .finder-group{
    a.btn-lg {
      margin-top: .1rem;
      margin-bottom: .15rem;
      position: relative;

      &.active {
        background: $primary !important;
        color: #fff;
        &::after{
          content: "close";
          color: white;
          font-family: 'Material Icons';
          font-weight: normal;
          font-style: normal;
          display: inline-block;
          line-height: 1.25;
          text-transform: none;
          letter-spacing: normal;
          word-wrap: normal;
          white-space: nowrap;
          direction: ltr;
          margin-left: .5rem;
          -webkit-font-smoothing: antialiased;
          text-rendering: optimizeLegibility;
          -moz-osx-font-smoothing: grayscale;
          font-feature-settings: 'liga';
          font-size: 24px;
        }
      }
    }

  }
}

@include media-breakpoint-down(sm) {
   .btn-primary {
     width: calc(50% - 10px);
     position:relative;
     margin: 0;
     display: flex;
     align-items: center;
     text-align: left;
     float: left;
     margin-bottom: $spacer;

     &:first-of-type {
       margin-right:20px;
     }

     i {
       display: inline-block;
       position: absolute;
       right: 1rem;
     }

     &.btn-light {
       font-size: 1rem;
       padding: 1rem 50px 1rem 1rem;

       .material-icons {
         line-height: 1;
       }
     }
   }

  form {
    .btn-primary {
      margin-top: $spacer;
    }
  }
 }

@include media-breakpoint-down(xs) {
  .btn-primary {
    width: 100%;

    &:first-of-type {
      margin-right: 0;
    }
  }
}