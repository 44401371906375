footer{
  background: $sw-bg-footer;
  padding: $spacer 0;
  color: #ddd;

  img {
    width: 272px;
    margin-top: $spacer/2;
  }

  a {
    margin: .65rem;
    display: inline-block;
  }

  span {
    margin-top: .65rem;
  }
}

@include media-breakpoint-up(xs) { }

@include media-breakpoint-up(sm) { }

@include media-breakpoint-up(md) { }

@include media-breakpoint-up(lg) { }

@include media-breakpoint-up(xl) { }

@include media-breakpoint-down(sm) {

  ul.nav {
    border-top: 1px solid rgba(255,255,255,.3);
    border-bottom: 1px solid rgba(255,255,255,.3);
    margin: $spacer 0;
    display: block;
    padding: $spacer/2 0;

    li {
      a {
        margin: .2rem .2rem .2rem 0;
      }
    }
  }
}