section,
.section {
  padding: 50px 0;
}

.box {
  position: relative;
  padding: $spacer;
  width: 100%;
  background: $light;
  margin-bottom: $spacer*2;
  &.box-without-icon{
    height: 100%
  }
}

.m-t-30{
  margin-top: 30px !important;
}

a.box  {
  &:hover {
    background: #eee;
  }
}

.media-sales-product {
  .box {
    position: relative;
    padding: 0;
    width: 100%;
    background: $light;
    margin-bottom: $spacer*2;
    border: 1px solid $light;

    .top {
      background-color: $white;
      height: 230px;
      overflow: hidden;
      text-align: right;
      position: relative;

      h3 {
        position: absolute;
        top: 15px;
        left: 1rem;
      }

      img {
        width: 60%;
        height: auto;
        -ms-transform: rotate(-8deg); /* IE 9 */
        -webkit-transform: rotate(-8deg); /* Safari 3-8 */
        transform: rotate(-8deg);
        margin: 50px auto 0 auto;
        display: block;
        -webkit-box-shadow: 10px 10px 5px 0px $gray-600;
        -moz-box-shadow: 10px 10px 5px 0px $gray-600;
        box-shadow: 10px 10px 5px 0px $gray-600;
      }

    }

    .bottom {
      padding: $spacer 40px $spacer $spacer;
      position: relative;
    }

  }
}

section.light {
  .box {
    background: $white;

    &:hover {
      background: $white;
    }
  }

}

.light {
  background: $light;
}

@include media-breakpoint-down(sm) {
  .products-overview {
    .col-md-3 {
      margin-bottom: $spacer*2;
    }
  }
}




