.navbar-light {
  border-top: 1px solid #eee;
  border-bottom: 2px solid #00A6D9;
  background-color: #fff !important;

  form {

    background: $light;

    i {
      display: inline-block;
    }

    .form-control {
      background: transparent;
      border-color: transparent;
      padding-top: .6rem;
      display: inline-block;
      width: auto;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .navbar-toggler{
    width: 50px;;
    border:none;
    margin-top: .1rem;
    margin-left: 2px;

    .line {
      width: 100%;
      float: left;
      height: 2px;
      background-color: #005977;
      margin-bottom: 4px;
    }
  }

  .nav-item {
    margin: 0;
    padding: 0;
  }
}

@include media-breakpoint-down(md) {

  .collapse:not(.show) {
    display: block;
    transition: all .5s ease;
  }

  .navbar-collapse {

    visibility: hidden;
    max-height: 0;
    overflow: hidden;
    display: none;
    opacity: 0;

    ul {
      padding: 15px;
    }
  }

  .navbar-collapse.in {

    max-height: 600px;
    visibility: visible;
    display: block;
    opacity: 1;
  }
}