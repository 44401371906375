.lang-badge {
  background: #838080;
  display: inline-block;
  padding: 2px 15px 0 15px;
  margin: 0 0 15px -15px;
  color: #fff;
  line-height: 1.5;
}

.products-overview,
 .products-magazine {
   [class^="col"] {

     padding-bottom: $spacer * 2;

     & > a {
       display: block;
       width: 100%;
       padding: $spacer;
       @include transition(background ease .5s);
     }
   }
 }

.products-magazine {
  [class^="col"] {

    & > a {

      &:hover {
        background: #eee;
      }
    }
  }
}

@include media-breakpoint-up(xs) { }

@include media-breakpoint-up(sm) {

  .products-overview,
  .products-magazine {
    [class^="col"] {

      & > a {

        i {
          @include transition(all .5s ease);
        }

        &:hover {
          i {
            right: 5px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {

}
@include media-breakpoint-up(lg) {

}
@include media-breakpoint-up(xl) {

}