/* hind-300 - latin-ext_latin */
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/hind-v16-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/hind-v16-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/hind-v16-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/hind-v16-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/hind-v16-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/hind-v16-latin-ext_latin-300.svg#Hind') format('svg'); /* Legacy iOS */
}

/* hind-regular - latin-ext_latin */
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/hind-v16-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/hind-v16-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/hind-v16-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/hind-v16-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/hind-v16-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/hind-v16-latin-ext_latin-regular.svg#Hind') format('svg'); /* Legacy iOS */
}

/* hind-500 - latin-ext_latin */
@font-face {
  font-family: 'Hind';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/hind-v16-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/hind-v16-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/hind-v16-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/hind-v16-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/hind-v16-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/hind-v16-latin-ext_latin-500.svg#Hind') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/MaterialIcons-Regular.eot'); /* For IE6-8 */
  src: local('Material Icons'),
  local('MaterialIcons-Regular'),
  url('../fonts/MaterialIcons-Regular.woff2') format('woff2'),
  url('../fonts/MaterialIcons-Regular.woff') format('woff'),
  url('../fonts/MaterialIcons-Regular.ttf') format('truetype');
}

