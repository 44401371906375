a {

  text-decoration: none !important;

  &.box {

    padding-bottom: $spacer;

    i {
      position:absolute;
      bottom: $spacer/2;
      right: $spacer/2;
      color: $primary;
    }
  }
}

.link-section {

  margin-top: $spacer*2;

  a {
    display: inline-flex;
    vertical-align: middle;
    i {
      line-height: .85;
    }
  }
}

footer {
  a {
    @include transition(color ease .5s);
    color: #ddd;

    &:hover {
      color: #fff;
    }
  }
}