.brands {
  @include make-row();

  .brand {
    margin-bottom: $spacer*2;
    min-width: 50px;
    min-height: 50px;
    img {
      padding: 5px 0;
      border-top: 2px solid $white;
      border-bottom: 2px solid $white;
    }

    @include make-col-ready();

    // 2 Colmuns Layout for 720px and down
    @media (max-width: map-get($grid-breakpoints, md)) {
      @include make-col(12/2);
    }

    // 3 Columns Layout for 720px to 960px
    @media (min-width: map-get($grid-breakpoints, md)) {
      @include make-col(12/3);
    }

    // 4 Columns Layout for 960px to 1140px
    @media (min-width: map-get($grid-breakpoints, lg)) {
      @include make-col(12/4);
    }

    // 5 Columns Layout for 1140px and up
    @media (min-width: map-get($grid-breakpoints, xl)) {
      @include make-col(12/5);
    }
  }
}