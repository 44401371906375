.overlay {
  z-index: 99;
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.hero-slide-wrapper {
  position: relative;
  z-index:2
}