.flickity-page-dots{
  .dot{
    opacity: 0.5;
    &.is-selected {
      background-color: #0088CC;
    }
  }
}



.slider-full-size-picture {
  width: 100%;
  height: 350px;
  overflow: hidden;

  .carousel-cell {
    width: 100%;
    height: 380px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;


    & > .container {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);

      p {
        background: rgba(0, 0, 0, .5);
        border-radius: 0 0 0 0.3125rem;
        line-height: 1.6;
        font-size: 1.4rem;
        color: #fff;
        display: inline-block;
        margin-bottom: 0;
        padding-bottom: 0.4375rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.4375rem;
      }
    }
  }
  .flickity-page-dots {
    .dot.is-selected {
      background-color: #0088CC;
    }
  }

  .flickity-page-dots {
    bottom: 25px;
  }




}

.slider-4column-with-cover {
  .carousel-cell {
    min-height: 100%;
    display: flex;
    height: 500px;
  }

  .flickity-page-dots {
    .dot.is-selected {
      background-color: #0088CC;
    }
  }

  .flickity-page-dots {
    bottom: -25px;
  }
}

$font-family-primary: 'Montserrat', sans-serif;
$font-family-secondary: 'Roboto Slab', serif;


.hero-slider {
  width: 100%;
  height: 500px;
  overflow: hidden;

  .carousel-cell {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    & > .container {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);

       p {
         background: rgba(0,0,0,.5);
        border-radius: 0 0 0 0.3125rem;
         line-height: 1.6;
         font-size: 1.4rem;
        color: #fff;
        display: inline-block;
        margin-bottom: 0;
        padding-bottom: 0.4375rem;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.4375rem;
      }
    }
  }

  .flickity-page-dots {
    bottom: 30px;
    .dot.is-selected {
      background-color: #0088CC;
    }
  }
}



.brandsSlider{
  .carousel-cell {
    width: 20%;
    min-height: 100px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-right: 15px;
    padding-left: 15px;
    img {
      padding: 5px 0;
      border-top: 2px solid $white;
      border-bottom: 2px solid $white;
      display: block;
    }
  }
}

.slider-teaser-with-image{
  margin: 0 -15px;

  .carousel-cell {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 992px) {
  .brandsSlider {
    .carousel-cell {
      width: 25%;
    }
  }
}

@media (max-width: 768px) {
  .brandsSlider {
    .carousel-cell {
      width: 33%;
    }
  }
}

@media (max-width: 320px) {
  .brandsSlider {
    .carousel-cell {
      width: 50%;
    }
  }
}


.productsOverviewSlider{
  margin: 0 -15px;

  .carousel-cell {
    min-height: 100%;
    display: flex;
    flex-flow: column;

    a.box{
      flex: 1;
      margin: 0;
    }
  }
}


