header {

  ul {
    li {
      a.nav-link {
        font-size: .8rem;
        color: #666;
        padding: 0 .3rem;
        line-height: .8;
      }

      &:last-child {
        margin-left: .3rem;
        padding-left: .3rem;
        border-left: 1px solid #666;
      }
    }
  }


}

@include media-breakpoint-down(md) {
  header {
    .logo {
      margin-top: $spacer;
    }
  }
}