aside {
  [class^="col"] {

    padding-bottom: $spacer * 2;

    & > a {
      background: $light;
      display: block;
      width: 100%;
      padding: $spacer;
      @include transition(background ease .5s);

      &:hover {
        background: #eee;
      }
    }
  }
}

@include media-breakpoint-up(xs) { }

@include media-breakpoint-up(sm) {

  aside {
    [class^="col"] {
      box-sizing: border-box;
      white-space: nowrap;

      &:before {
        @include aside-square-helper();
      }

      & > a {
        @include aside-square-helper();
        @include relative-block-full();

        * {
          position: absolute;
          padding: $spacer;
        }

        div   { left: 0;  top: 0;     }
        p     { left: 0;  top: 35%;   }

        i {
          right: 0;
          bottom: 0;
          @include transition(all .5s ease);
        }

        &:hover {
          i {
            right: -5px;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {

}
@include media-breakpoint-up(lg) {

}
@include media-breakpoint-up(xl) {

}

