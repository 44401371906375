.contact-person,
.persons {
  h3 {
    margin-bottom: 0;
    color: $body-color;
  }
}

.contact-person {
  h2 {
    border-bottom: 0;
    margin: 0;
    padding-bottom: $spacer/2;
  }

  img {
    margin-bottom: $spacer;
  }
}

.persons{
  .person-wrapper{
    margin-bottom: 2rem;
  }
  .box-wrapper{
    height: 100%;
    background: $light;

    box-sizing: border-box;
    .box{
      margin-bottom: 0;
    }
  }




  video{
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    &::-webkit-media-controls {
      display:none !important;
    }
  }
}


