.grid-icon {
  display: inline-block;
  margin: 0;
}

.grid-icon > .layer {
  width: 100%;
  position: absolute;
  left: 0;
}

.grid-icon {
  position: relative;
  width: 12px;
  height: 12px;
}

.grid-icon > .layer {
  height: 5px;
}

.grid-icon > .primary {
  top: 0;
}

.grid-icon > .secondary {
  bottom: 0;
}

.grid-icon > .layer > span {
  position: absolute;
  display: block;
  width: 5px;
  height: 100%;
  box-sizing: border-box;
  border: solid 1px #999;
}

.grid-icon2 > .layer > span:first-child {
  left: 0;
}

.grid-icon > .layer > span:nth-child(2) {
  right: 0;
}

.finder strong {
  font-size: 1.1rem;
}

.grid a.box {
  min-height: 490px;
}

.d-flex-grid {
  display: flex;
}

.grid {
  .d-flex-grid {
    a.box {
      min-height: auto;
      .bottom{
        min-height: 210px;
      }
    }
  }
}

.finder {
  .finder-group {
    input[type=checkbox] {
      opacity: 0;
      position: absolute;
    }

    input[type=checkbox] + label {
      cursor: pointer;
    }

    input[type=checkbox]:checked + label {
      background: $primary !important;
      color: #fff;

      &::after {
        content: "close";
        color: white;
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        display: inline-block;
        line-height: 1.25;
        text-transform: none;
        letter-spacing: normal;
        word-wrap: normal;
        white-space: nowrap;
        direction: ltr;
        margin-left: .5rem;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        font-feature-settings: 'liga';
        font-size: 24px;
      }
    }
  }
  .grid-wrapper{
    min-height: 600px;
    .meta-navi{
      font-size: 1.25rem;
      line-height: 1.5;
      padding: 0.5rem 0;
      margin-bottom: 20px;
      .meta-navi-results{
        span{
          color: #486A7E;
          font-weight: 400;
        }
      }
      a{
        display: inline-block;
        font-size: 1.25rem;
        line-height: 1.5rem;
        padding:  0 0.2em;
        color: #212529;
        &.active{
          font-size: 1.5rem;
        }
      }
    }
  }
}